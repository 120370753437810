import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      axios.post(`${config.BaseUrl}/token/verify/`, { token: accessToken })
        .then(() => {
          setAuth(accessToken);
          setLoading(false);
        })
        .catch(() => {
          // Access token is invalid, try to refresh it
          axios.post(`${config.BaseUrl}/token/refresh/`, { refresh: refreshToken })
            .then(response => {
              localStorage.setItem('access_token', response.data.access);
              setAuth(response.data.access);
            })
            .catch(() => {
              // Refresh token is invalid, clear storage and set auth to null
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              setAuth(null);
            })
            .finally(() => {
              setLoading(false);
            });
        });
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${config.BaseUrl}/token/`, {
        username,
        password
      });
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      setAuth(response.data.access);
    } catch (error) {
      console.error("Login failed: ", error);
      throw error; // Rethrow the error to handle it in the UI
    }
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setAuth(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
