import React, { useState, useContext } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import config from '../config';
import { AuthContext } from './AuthContext';

const PurchaseOrderStatus = () => {
  const {auth} = useContext(AuthContext);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderStatusResult, setOrderStatusResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${config.BaseUrl}/api/orderstatus/${orderStatus}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      setOrderStatusResult(response.data);
      console.log(response.data);
    } catch (error) {
      setError("Error fetching order status");
      setOrderStatusResult("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" component="h1" gutterBottom>
        Purchase Order Status
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
          width: '100%',
          borderRadius: '10px',
          boxShadow: '4'
        }}
      >
        <Box
          component="form"
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            id="orderStatus"
            label="Purchase Order Number"
            variant="outlined"
            size="small"
            fullWidth
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
          />
          <Button variant="contained" color="primary" type="submit">
            {loading ? 'Loading...' : 'Submit'}
          </Button>
        </Box>
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
        {orderStatusResult && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 4,
              padding: 2,
              width: '100%',
              borderRadius: '10px',
            }}
          >
            <Typography variant="h8">Order Status: {orderStatusResult.purchaseOrderStatus}</Typography>
            <Typography variant="h8">Order Number: {orderStatusResult.orderNumber}</Typography>
            <Typography variant="h8">Estimated Shipping Date: {orderStatusResult.estimatedShippingDate}</Typography>
            <Typography variant="h8">Estimated Delivery Date: {orderStatusResult.estimatedDeliveryDate}</Typography>
            {orderStatusResult.waybills && (
              <Typography variant="h8">Waybills: {orderStatusResult.waybills.join(", ")}</Typography>
            )} 
            {orderStatusResult.serviceTags && orderStatusResult.serviceTags.length > 0 && (
              <Typography variant="h8">Service Tags: {orderStatusResult.serviceTags.join(", ")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PurchaseOrderStatus;
