import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Modal, TextField, Autocomplete, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import config from '../config';
import { AuthContext } from './AuthContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

const QuoteModal = ({ quoteNum }) => {
    const {auth} = useContext(AuthContext);
    const [oemBase, setOemBase] = useState("");
    const [customer, setCustomer] = useState(null);
    const [open, setOpen] = useState(false);
    const [newBaseOpen, setNewBaseOpen] = useState(false);
    const [bases, setBases] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [newBaseName, setNewBaseName] = useState("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleNewBaseOpen = () => setNewBaseOpen(true);
    const handleNewBaseClose = () => setNewBaseOpen(false);

    const fetchBases = async () => {
        try {
            const response = await axios.get(`${config.BaseUrl}/api/bases/`, {
                headers: {
                    Authorization: `Bearer ${auth}`,
                  },
            });
            setBases(response.data);
        } catch (error) {
            console.error('Error fetching bases:', error);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await axios.get(`${config.BaseUrl}/api/customers/`, {
                headers: {
                    Authorization: `Bearer ${auth}`,
                  },
            });
            setCustomers(response.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    useEffect(() => {
        fetchBases();
        fetchCustomers();
    }, []);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const csrfToken = getCookie('csrftoken');

        let quote = quoteNum.split('.');
        const id = quote[0];
        const v = quote[1];
        const base = oemBase.base;

        const url = `${config.BaseUrl}/api/savequote/`;
        const data = {
            id: id,
            version: v,
            OEMBase: base
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${auth}`,

                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                setSnackbarMessage("Quote saved successfully!");
                setSnackbarSeverity("success");
            } else {
                setSnackbarMessage("Failed to save the quote.");
                setSnackbarSeverity("error");
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage("An error occurred while saving the quote.");
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            handleClose();
        }
    };

    const handleNewBaseSubmit = async () => {
        setLoading(true);

        const csrfToken = getCookie('csrftoken');

        const url = `${config.BaseUrl}/api/createbase/`;
        const data = {
            base: newBaseName,
            customer: customer.pk
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${auth}`,
                },
                body: JSON.stringify(data),
            });

            if (response.status === 200) {
                const newBase = await response.json();
                console.log('New base created:', newBase.base);
                setSnackbarMessage("New base created successfully!");
                setSnackbarSeverity("success");
                setNewBaseName("");
                setCustomer(null);
                setBases([...bases, newBase.base]);
                setOemBase(newBase); // Set the newly created base as the selected base
                handleNewBaseClose(); // Close the new base modal
            } else {
                setSnackbarMessage("Failed to create the new base.");
                setSnackbarSeverity("error");
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage("An error occurred while creating the new base.");
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" sx={{ margin: 2 }} onClick={handleOpen}>Save Quote</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Save Quote
                    </Typography>
                    <Autocomplete
                        sx={{ mt: 2 }}
                        id="oemBase"
                        fullWidth
                        options={bases}
                        getOptionLabel={(option) => option.base ? option.base : oemBase}
                        value={oemBase}
                        onChange={(event, newValue) => setOemBase(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Select a Base" variant="outlined" size="small" />
                        )}
                    />
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={handleNewBaseOpen} sx={{ mt: 2 }} variant="outlined" color="primary">
                            Add New Base
                        </Button>
                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={newBaseOpen}
                onClose={handleNewBaseClose}
                aria-labelledby="modal-new-base-title"
                aria-describedby="modal-new-base-description"
            >
                <Box sx={style}>
                    <Typography id="modal-new-base-title" variant="h6" component="h2">
                        Create New Base
                    </Typography>
                    <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Base Name"
                        variant="outlined"
                        value={newBaseName}
                        onChange={(e) => setNewBaseName(e.target.value)}
                        size="small"
                    />
                    <Autocomplete
                        sx={{ mt: 2 }}
                        id="customer"
                        options={customers}
                        getOptionLabel={(option) => option.customer ? option.customer : ""}
                        value={customer}
                        onChange={(event, newValue) => setCustomer(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Select a Customer" variant="outlined" size="small" />
                        )}
                    />
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNewBaseSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Create'}
                    </Button>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default QuoteModal;
