import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .row-green': {
    backgroundColor: 'lightgreen',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  '& .row-red': {
    backgroundColor: 'lightcoral',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
}));

const columns = [
  // {
  //   field: 'id',
  //   headerName: 'ID',
  //   width: 50,
  // },
  {
    field: 'match',
    headerName: 'Match',
    type: 'boolean',
    width: 80,
  },
  {
    field: 'sku',
    headerName: 'SKU',
    width: 100,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1, // Use flex to make the column flexible
    minWidth: 200, // Set a minimum width for the column
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 100,
  },
];

export default function QuoteGrid({ data }) {
  const getRowClassName = (params) => {
    return params.row.match ? 'row-green' : 'row-red';
  };

  // Create a new sorted array
  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => a.match - b.match);
  }, [data]);

  return (
    <Box sx={{ height: 550, width: '100%' }}>
      <StyledDataGrid
        rows={sortedData}
        columns={columns}
        disableRowSelectionOnClick
        getRowClassName={getRowClassName}
      />
    </Box>
  );
}
