// import React, { useState, useEffect, useContext } from 'react';
// import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import QuoteGrid from './QuoteGrid';
// import QuoteModal from './saveQuote';
// import config from '../config';
// import { AuthContext } from './AuthContext';

// const FormComponent = () => {
//   const { auth } = useContext(AuthContext);
//   const location = useLocation();
//   const initialQuoteNum = location.state?.quoteNum || '';

//   const [quoteNum1, setQuoteNum1] = useState(initialQuoteNum);
//   const [quoteNum2, setQuoteNum2] = useState('');
//   const [quote1, setQuote1] = useState([]);
//   const [quote2, setQuote2] = useState([]);
//   const [match, setMatch] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchQuote = async (id, version) => {
//     try {
//       const response = await axios.get(
//         `${config.BaseUrl}/quotes/${id}/${version}/`,
//         {
//           headers: {
//             Authorization: `Bearer ${auth}`,
//           },
//         }
//       );
//       return response.data.items;
//     } catch (error) {
//       throw new Error('Resource not found');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     const [id1, v1] = quoteNum1.split('.');
//     const [id2, v2] = quoteNum2.split('.');

//     try {
//       if (quoteNum1) {
//         const quoteData1 = await fetchQuote(id1, v1);
//         setQuote1(quoteData1);
//       }

//       if (quoteNum2) {
//         const quoteData2 = await fetchQuote(id2, v2);
//         setQuote2(quoteData2);
//       }

//       if (quoteNum1 && quoteNum2) {
//         const response = await axios.get(
//           `${config.BaseUrl}/quotes/compare/${id1}/${v1}/${id2}/${v2}/`,
//           {
//             headers: {
//               Authorization: `Bearer ${auth}`,
//             },
//           }
//         );
//         setMatch(response.data.match);
//       }
//     } catch (error) {
//       console.log(error)
//       if (error.response && error.response.data && error.response.data.message) {
//         setError(error.response.data.message);
//       } else {
//         setError('An error occurred');
//       }
//       setQuote1([]);
//       setQuote2([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (initialQuoteNum) {
//       setQuoteNum1(initialQuoteNum);
//     }
//   }, [initialQuoteNum]);

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6" component="h1" gutterBottom>
//         Compare Quotes
//       </Typography>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           width: '100%',
//         }}
//       >
//         <Box
//           component="form"
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             padding: 2,
//             width: '100%',
//             borderRadius: '10px',
//             boxShadow: 4,
//           }}
//           noValidate
//           autoComplete="off"
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               gap: 2,
//               alignItems: 'center',
//               width: '50%',
//               mr: 1,
//             }}
//           >
//             <TextField
//               id="quoteNum1"
//               label="First Quote Number"
//               variant="outlined"
//               size="small"
//               value={quoteNum1}
//               onChange={(e) => setQuoteNum1(e.target.value)}
//             />
//           </Box>
//           <Box
//             sx={{
//               display: 'flex',
//               gap: 2,
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               width: '50%',
//             }}
//           >
//             <Box>
//               <TextField
//                 sx={{ pr: 2 }}
//                 id="quoteNum2"
//                 label="Second Quote Number"
//                 variant="outlined"
//                 size="small"
//                 value={quoteNum2}
//                 onChange={(e) => setQuoteNum2(e.target.value)}
//               />
//             </Box>
//             <Button variant="contained" color="primary" type="submit" onClick={handleSubmit} disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'Submit'}
//             </Button>
//           </Box>
//         </Box>
//         {error && <Typography sx={{ m: 4 }} color="error">{error}</Typography>}
//           <Box
//             sx={{
//               mt: 4,
//               width: '100%',
//               borderRadius: '10px',
//               boxShadow: 4,
//               textAlign: 'center',
//             }}
//           > {quoteNum1 && quoteNum2 && (
//             match ? (
//               <Typography variant="h6" sx={{ m: 2 }} color="green">
//                 Complete Match
//               </Typography>
//             ) : (
//               <Typography variant="h6" sx={{ m: 2 }} color="error">
//                 Errors Found
//               </Typography>
//             )
//           )}
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 padding: 2,
//                 width: '100%',
//               }}
//             >
//             {quote1.length > 0 && (
//               <Box
//                 sx={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyItems: 'center',
//                   width: '50%',
//                   mr: 1,
//                 }}
//               >
//                 <QuoteGrid data={quote1} />
//                 <QuoteModal quoteNum={quoteNum1} />
//               </Box>
//             )}
//               {quote2.length > 0 && (
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyItems: 'center',
//                     width: '50%',
//                     ml: 1,
//                   }}
//                 >
//                   <QuoteGrid data={quote2} />
//                   <QuoteModal quoteNum={quoteNum2} />
//                 </Box>
//               )}
//             </Box>
//           </Box>
//       </Box>
//     </Box>
//   );
// };

// export default FormComponent;

import React, { useState, useEffect, useContext } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import QuoteGrid from './QuoteGrid';
import QuoteModal from './saveQuote';
import config from '../config';
import { AuthContext } from './AuthContext';

const FormComponent = () => {
  const {auth} = useContext(AuthContext);
  const location = useLocation();
  const initialQuoteNum = location.state?.quoteNum || '';

  const [quoteNum1, setQuoteNum1] = useState(initialQuoteNum);
  const [quoteNum2, setQuoteNum2] = useState('');
  const [quote1, setQuote1] = useState([]);
  const [quote2, setQuote2] = useState([]);
  const [match, setMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    let quote1 = quoteNum1.split('.');
    let quote2 = quoteNum2.split('.');

    const id1 = quote1[0];
    const id2 = quote2[0];
    const v1 = quote1[1];
    const v2 = quote2[1];

    try {
      const response = await axios.get(
        `${config.BaseUrl}/api/quotes/compare/${id1}/${v1}/${id2}/${v2}/`, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      setQuote1(response.data.quote1.items);
      setQuote2(response.data.quote2.items);
      setMatch(response.data.match);
    } catch (error) {
      setError('Resource not found');
      setQuote1([]);
      setQuote2([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialQuoteNum) {
      setQuoteNum1(initialQuoteNum);
    }
  }, [initialQuoteNum]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" component="h1" gutterBottom>
        Compare Quotes
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            width: '100%',
            borderRadius: '10px',
            boxShadow: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              width: '50%',
              mr: 1,
            }}
          >
            <TextField
              id="quoteNum1"
              label="First Quote Number"
              variant="outlined"
              size="small"
              value={quoteNum1}
              onChange={(e) => setQuoteNum1(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '50%',
            }}
          >
            <Box>
              <TextField
                sx={{ pr: 2 }}
                id="quoteNum2"
                label="Second Quote Number"
                variant="outlined"
                size="small"
                value={quoteNum2}
                onChange={(e) => setQuoteNum2(e.target.value)}
              />
            </Box>
            <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Box>
        </Box>
        {error && <Typography sx={{ m: 4 }} color="error">{error}</Typography>}
        {quote1.length > 0 && (
          <Box
            sx={{
              mt: 4,
              width: '100%',
              borderRadius: '10px',
              boxShadow: 4,
              textAlign: 'center',
            }}
          >
            {!match && (
              <Typography variant="h6" sx={{ m: 2 }} color="error">
                Incomplete Match
              </Typography>
            )}
            {match && (
              <Typography variant="h6" sx={{ m: 2 }} color="green">
                Complete Match
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: 2,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyItems: 'center',
                  width: '50%',
                  mr: 1,
                }}
              >
                <QuoteGrid data={quote1} />
                <QuoteModal quoteNum={quoteNum1} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyItems: 'center',
                  width: '50%',
                  ml: 1,
                }}
              >
                <QuoteGrid data={quote2} />
                <QuoteModal quoteNum={quoteNum2} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormComponent;
