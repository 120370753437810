import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MiniDrawer from './components/Sidebar';
import QuoteSearch from './components/QuoteSearch';
import Profile from './components/Profile';
import FormComponent from './components/QuoteForm';
import PurchaseOrderStatus from './components/OrderStatus';
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import { AuthContext } from './components/AuthContext';

function App() {
  const { auth } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/*"
          element={
            auth ? (
              <MiniDrawer>
                <Routes>
                  <Route path="/quotesearch" element={<QuoteSearch />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/formcomponent" element={<FormComponent />} />
                  <Route path="/orderstatus" element={<PurchaseOrderStatus />} />
                  <Route path="*" element={<Navigate to="/formcomponent" />} />
                </Routes>
              </MiniDrawer>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
