import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, CircularProgress, Autocomplete, Snackbar, Alert, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { AuthContext } from './AuthContext';

const QuoteSearch = () => {
  const { auth } = useContext(AuthContext);
  const [selectedBase, setSelectedBase] = useState(null);
  const [bases, setBases] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState([]);
  const [quoteDetailsLoading, setQuoteDetailsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBases = async () => {
      try {
        const response = await axios.get(`${config.BaseUrl}/api/bases/`, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
        setBases(response.data);
      } catch (error) {
        console.error('Error fetching bases:', error);
      }
    };
    fetchBases();
  }, [auth]);

  const handleSearch = async () => {
    if (!selectedBase) {
      setError('Please select a base');
      setSnackbarMessage('Please select a base');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.BaseUrl}/api/quotes/${selectedBase.base}/`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      const sortedQuotes = response.data.sort((a, b) => new Date(b.quoted_on) - new Date(a.quoted_on));
      setQuotes(sortedQuotes);
      setSnackbarMessage('Quotes fetched successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      setError('Base not found or an error occurred');
      setSnackbarMessage('Base not found or an error occurred');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCompareClick = (quoteNumber) => {
    navigate(`/formcomponent`, { state: { quoteNum: quoteNumber } });
  };

  const handleDetailsClick = async (quote) => {
    setQuoteDetailsLoading(true);
    setModalOpen(true);
    let quote_num = quote.split(".")
    let id = quote_num[0]
    let version = quote_num[1]

    try {
      const response = await axios.get(`${config.BaseUrl}/api/quotes/${id}/${version}/`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      setQuoteDetails(response.data.items);
    } catch (error) {
      console.error('Error fetching quote details:', error);
    } finally {
      setQuoteDetailsLoading(false);
    }
  };

  const columns = [
    {
      field: 'compare',
      headerName: 'Compare',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCompareClick(params.row.quote_number)}
        >
          Compare
        </Button>
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleDetailsClick(params.row.quote_number)}
        >
          Details
        </Button>
      ),
    },
    { field: 'quote_number', headerName: 'Quote Number', width: 150 },
    { field: 'customer_number', headerName: 'Customer Number', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'quantity', headerName: 'Quantity', width: 100 },
    { field: 'quoted_on', headerName: 'Quoted On', width: 150 },
    { field: 'expiration', headerName: 'Expiration', width: 150 },
    { field: 'total_cost', headerName: 'Total Cost', width: 150 },
    { field: 'sales_rep', headerName: 'Sales Rep', width: 150 },
    { field: 'shipping_method', headerName: 'Shipping Method', width: 150 },
    { field: 'currency', headerName: 'Currency', width: 100 },
  ];

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ my: 2, width: '100%' }}>
        <Typography variant="h6" component="h1" gutterBottom>
          Search Quotes by Base
        </Typography>
        <Box
          sx={{
            display: 'flex',
            mb: 2,
            padding: 2,
            width: '100%',
            borderRadius: '10px',
            boxShadow: 4,
          }}
        >
          <Autocomplete
            sx={{ pr: 2, flexGrow: 1 }}
            id="baseName"
            options={bases}
            getOptionLabel={(option) => option.base || ''}
            value={selectedBase}
            onChange={(event, newValue) => setSelectedBase(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select a Base" variant="outlined" size="small" />
            )}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Search'}
          </Button>
        </Box>
        {error && (
          <Typography color="error" variant="body1" gutterBottom>
            {error}
          </Typography>
        )}
        <Box
          sx={{
            height: 600,
            width: '100%',
            padding: 2,
            borderRadius: '10px',
            boxShadow: 4,
          }}
        >
          <DataGrid rows={quotes} columns={columns} getRowId={(row) => row.quote_number} />
        </Box>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="modal-title" variant="h6" component="h2">
              Quote Details
            </Typography>
            <Box
              sx={{
                height: 400,
                width: '100%',
                marginTop: 2,
              }}
            >
              {quoteDetailsLoading ? (
                <CircularProgress />
              ) : (
                <DataGrid rows={quoteDetails} columns={[
                  { field: 'sku', headerName: 'SKU', width: 200 },
                  { field: 'quantity', headerName: 'Quantity', width: 150 },
                  { field: 'description', headerName: 'Description', width: 200 },
                ]} getRowId={(row) => row.id} />
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default QuoteSearch;
